import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Form from '../components/UI/contact/form'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import GridContent from '../components/UI/grid-content'

const Contact = ({ data }) => {
  const { contacts } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  return (
    <Layout
      location={typeof window !== 'undefined' && location}
      crumbLabel="Contact Us"
    >
      <GatsbySeo
        title="Contact Us"
        description="Please get in touch with any questions, we're here to help."
      />
      <GridContent
        id="contact"
        layout="--center-4 fullscreen"
        mode={contacts.mode}
        background={contacts.image}
      >
        {contacts.section && <h4>{contacts.section}</h4>}
        {contacts.statement && <h1>{contacts.statement}</h1>}

        <Form />
      </GridContent>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "contact" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              contacts {
                mode
                section
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                address
                statement
              }
            }
          }
        }
      }
    }
  }
`
