import React, { useState } from 'react'

import './form.css'
const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Form = () => {
  const [details, setDetails] = useState({
    name: '',
    email: '',
    message: '',
    thanks: false,
  })

  const [thanks, setThanks] = useState(false)

  const [errors, setErrors] = useState({
    errorName: '',
    errorEmail: '',
    errorMessage: '',
  })

  const handleChange = e => {
    setDetails({ ...details, [e.target.name]: e.target.value })

    setErrors({
      errorName: '',
      errorEmail: '',
      errorMessage: '',
    })
  }

  function handleValidation() {
    let formIsValid = true
    let name = details.name
    let email = details.email
    let message = details.message
    let lastAtPos = details.email.lastIndexOf('@')
    let lastDotPos = details.email.lastIndexOf('.')

    if (!name) {
      formIsValid = false
      setErrors({ errorName: 'This cannot be empty' })
    }

    if (!message) {
      formIsValid = false
      setErrors({ errorName: 'Please let us know what you are interested in?' })
    }

    if (!email) {
      formIsValid = false
      setErrors({ errorEmail: 'This cannot be empty' })
    } else if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        details.email.indexOf('@@') === -1 &&
        lastDotPos > 2 &&
        details.email.length - lastDotPos > 2
      )
    ) {
      formIsValid = false
      setErrors({ errorEmail: 'Email is not valid' })
    }

    return formIsValid
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (handleValidation()) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', ...details }),
      })
        .then(() => {
          console.log('Form succesfully sent.')
          setThanks(!thanks)
        })
        .catch(error => alert(error))
    }
  }

  return (
    <>
      {thanks ? (
        <div className="thanks">
          <h1>Thank you, someone will be in touch.</h1>
        </div>
      ) : (
        <>
          <form
            data-netlify="true"
            // data-netlify-recaptcha="true"
            name="contact"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            {/* <div data-netlify-recaptcha="true" /> */}
            <div className="input-box" style={{ gridArea: 'name' }}>
              <input
                type="text"
                name="name"
                placeholder="Name:"
                onChange={handleChange}
              />
              <label>{errors.errorName}</label>
            </div>
            <div className="input-box" style={{ gridArea: 'email' }}>
              <input
                type="text"
                name="email"
                placeholder="Email:"
                onChange={handleChange}
              />
              <label>{errors.errorEmail}</label>
            </div>
            <div className="input-box" style={{ gridArea: 'message' }}>
              <textarea
                type="text"
                name="message"
                placeholder="Message:"
                rows="5"
                onChange={handleChange}
              />
              <label>{errors.errorMessage}</label>
            </div>

            <button
              role="button"
              aria-label="Submit"
              className="submit leopard mock-button"
            >
              <h4>Submit</h4>
            </button>
          </form>
        </>
      )}
    </>
  )
}

export default Form
